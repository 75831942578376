import React, { useState } from 'react';
import './App.css';
import overlayImage from './googleplaystore.png';
import ReactPlayer from 'react-player'

function App() {
  const handleButtonClick = () => {
    // Add functionality here for what happens when the button is clicked
    // alert('Button clicked!');
    window.location.href = 'https://play.google.com/store/apps/details?id=com.vacav';
  };
  const handleVideoEnd = () => {
    console.log('Video ended!');
    setPanelVisible(true);
    // Add your logic here for what to do when the video ends
  };
  const [isPanelVisible, setPanelVisible] = useState(true);

  return (
    <div style={{
      width: '100%',
      height: "100vh",
    }}
    >
      <ReactPlayer
        style={{
          zIndex: 999,
        }}
        className='react-player fixed-bottom'
        url='https://vacavcloudpublic.s3.ap-south-1.amazonaws.com/VacavExplainerVideo.mp4'
        width='100%'
        height="calc(100vh - 55px)"
        controls={true}
        onEnded={handleVideoEnd}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, 0%)',
          paddingLeft: 10,
          paddingRight: 10,
          alignSelf: 'center',
          color: '#fff',
          alignContent: 'center',
          // marginLeft: 10,
          // marginRight: 10,
          background: '#c9a0dc',
          borderRadius: 10,
          width: '100%',
          height: 55,
          flex: 1,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row'
        }}
        onClick={handleButtonClick}
      >
        <div  style={{
          alignSelf: 'center',
          alignContent: 'center',
          flex: 1,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <h3 style={{ margin: 0, paddingTop: 5, alignSelf: 'center' }}>Early signup</h3>
          <h3 style={{ margin: 0, paddingTop: 5,paddingBottom:5, alignSelf: 'center' }}>available</h3>
        </div>
        <img src={overlayImage} alt="Overlay"
          onClick={handleButtonClick}
          style={{
            width: 153,
            height: 45,
            padding: 5
            // position: 'absolute',
            // bottom: 0,
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            // padding: 10,
            // color: '#fff',
          }}
        />
      </div>
    </div>
  );

}



export default App;
